import { Box, Button, FormControl, Input, Text, InputGroup, InputRightElement } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import { ReactComponent as Apple } from "../../images/apple.svg";
import AuthLayout from "../../layouts/AuthLayout";

const Login = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickPassword = () => setShowPassword(!showPassword);  

  const handleInputChange = (field: "email" | "password", value: string) => {
    setInput({ ...input, [field]: value });
  };

  const submit = async () => {
    if (!input.email || !input.password)
      throw new Error("Please enter all required fields");

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}accounts/signin`,
      input,
      {
        headers: {
          "application-authorization-token":
            process.env.REACT_APP_BACKEND_AUTH_TOKEN,
        },
      }
    );

    localStorage.setItem("token", res.data.token);
    window.location.href = "/dashboard";
  };

  const { mutate, isLoading } = useMutation(submit, {
    onError: (e: Error | AxiosError) => {
      return alert(
        "There was an issue logging in, please check your username and password are valid and try again"
      );
    },
  });

  if (isLoading) return <LoadingAnimation />;

  return (
  <AuthLayout>
    <Box position="relative" mx="auto" left="-14">
      <Box position="absolute" top="-28">
        <Apple />
      </Box>
    </Box>
    <form onSubmit={() => mutate()}>
      <Text
        fontSize="3xl"
        fontWeight="bold"
        mx="auto"
        mb="35"
        mt="5"
        align="center"
      >
        Account Login
      </Text>
      <Box display="flex" flexDir="column" alignContent="center">
        <FormControl>
          <Input
            borderColor="shakumaku.grey"
            borderWidth="1.5px"
            p="6"
            placeholder="Email Address"
            required
            type="email"
            value={input.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            rounded="full"
          />
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              borderColor="shakumaku.grey"
              borderWidth="1.5px"
              my="4"
              p="6"
              rounded="full"
              placeholder="Password"
              required
              type={showPassword ? "text" : "password"}
              value={input.password}
              onChange={(e) => handleInputChange("password", e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button 
                h="1.75rem" 
                size="sm" 
                onClick={handleClickPassword}
                position="absolute"
                transform="translateY(75%)"
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Text
            mt="1"
            fontSize="sm"
            cursor="pointer"
            onClick={() => navigate("/request-reset-password")}
            decoration="underline"
            textAlign="center"
          >
            Forgot Password?
          </Text>
        </FormControl>
        <Button
          type="submit"
          variant="secondary"
          mx="auto"
          w="40"
          my="6"
          rounded="full"
          isDisabled={!input.email || !input.password}
        >
          Submit
        </Button>
        <Text
          fontSize="sm"
          cursor="pointer"
          textAlign="center"
          color="lightgrey"
          onClick={() => navigate("/signup")}
        >
          Don't have an account?{" "}
          <Text as="span" color="shakumaku.blue" decoration="underline">
            Sign Up
          </Text>
        </Text>
      </Box>
    </form>
  </AuthLayout>

  );
};

export default Login;
